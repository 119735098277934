import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
//import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider } from 'firebase/auth';
import { getAuth, signInWithPhoneNumber, PhoneAuthProvider, RecaptchaVerifier, signInWithCredential } from 'firebase/auth';

import '../css/general-page.css';
import logo from '../images/logo-blue.png'; // Importujte logo
import Footer from '../components/Footer';

import NavBarPublic from '../components/NavBarPublic';



const signInWithPhoneNumberContext = signInWithPhoneNumber;


const LoginPage = () => {
  const { currentUser } = useAuth();
  const emailRef = useRef();
  const passwordRef = useRef();
  const pinRef = useRef();
  const { login, signInWithGoogle, loginAsSubUser, signInWithSMS } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState('main');
  const navigate = useNavigate();

  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [verificationId, setVerificationId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  
  const recaptchaContainerRef = useRef();

  

  useEffect(() => {
    const auth = getAuth();
    const recaptchaVerifier = new RecaptchaVerifier(recaptchaContainerRef.current, {}, auth);
    setRecaptchaVerifier(recaptchaVerifier);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      if (accountType === 'main') {
        await login(emailRef.current.value, passwordRef.current.value);
      } else {
        await loginAsSubUser(emailRef.current.value, pinRef.current.value);
      }
      navigate('/');
    } catch {
      setError('Nepodarilo sa prihlásiť');
    }

    setLoading(false);
  }

  const handleSignInWithGoogle = async () => {
    try {
      setError('');
      setLoading(true);
      await signInWithGoogle();
      navigate('/app');
    } catch (error) {
      console.error('Prihlásenie cez Google zlyhalo:', error);
      setError('Nepodarilo sa prihlásiť');
    }

    setLoading(false);
  };

  const sendPhoneNumber = async () => {
    // Initialize reCAPTCHA
    if (!recaptchaVerifier) {
      const verifier = new RecaptchaVerifier(recaptchaContainerRef.current, {
        size: 'invisible',
      });
      setRecaptchaVerifier(verifier);
    }

    try {
      setError('');
      setLoading(true);
      const auth = getAuth();
      const provider = new PhoneAuthProvider(auth);
      const verificationId = await provider.verifyPhoneNumber(phoneNumber, recaptchaVerifier);
      setVerificationId(verificationId);
      setLoading(false);
    } catch (error) {
      console.error('Chyba pri overovaní telefónneho čísla:', error);
      setError('Nepodarilo sa overiť telefónne číslo');
      setLoading(false);
    }
  };


  const verifyCode = async () => {
    try {
      setError('');
      setLoading(true);
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      await signInWithSMS(credential);
      navigate('/');
    } catch (error) {
      console.error('Chyba pri overovaní kódu:', error);
      setError('Nepodarilo sa overiť kód');
      setLoading(false);
    }
  };
  
  

  return (
    
    <div className="background-none">
      {!currentUser ? <NavBarPublic /> : null}


      <div className="login-page">
      <Card className="login-card">
        <Card.Body>
        <a href="/">
          <img src={logo} alt="Logo" className="app-logo" />
        </a>
          <h2 className="login-title text-center mb-4">Prihlásenie</h2>
          <p className="center">Pre realitné kancelárie s mesačným <Link className="login-register-link" to="/cennik">predplatným</Link>.</p>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label className="login-form-label">Email</Form.Label>
              <Form.Control className="login-form-input" type="email" ref={emailRef} required />
            </Form.Group>
            {accountType === 'main' ? (
              <Form.Group id="password">
                <Form.Label className="login-form-label">Heslo</Form.Label>
                <Form.Control className="login-form-input" type="password" ref={passwordRef} required />
              </Form.Group>
            ) : (
              <Form.Group id="pin">
                <Form.Label className="login-form-label">PIN</Form.Label>
                <Form.Control className="login-form-input" type="text" ref={pinRef} required />
              </Form.Group>
            )}
            <Button disabled={loading} className="login-form-btn" type="submit">
              Prihlásiť sa
            </Button>
          </Form>
          {accountType === 'main' && (
            <>
              <div className="login-separator">alebo</div>
              <Button disabled={loading} className="login-form-btn login-google-btn" onClick={handleSignInWithGoogle}>
                Prihlásiť sa cez Google
              </Button>
            </>
          )}
          <div className="login-register">
            Nemáte účet? <Link className="login-register-link" to="/register">Registrovať sa</Link> Zabudnuté heslo? <Link className="login-register-link" to="/reset">Resetovať heslo</Link>
          </div>
          <div ref={recaptchaContainerRef}></div>
      
        </Card.Body>
      </Card>
      <Footer />
    </div>
    </div>
  );
};

export default LoginPage;
